import Order from "../Order";
import {Currency, Rate, LevelRate, RevisionRate, ExchangeRate} from "../Currency";

class AE
{
	static LevelRateMap: Map<string, LevelRate | Promise<LevelRate>> = new Map<string, LevelRate | Promise<LevelRate>>();
	static RevisionRateMap: Map<string, RevisionRate | Promise<RevisionRate>> = new Map<string, RevisionRate | Promise<RevisionRate>>();
	static ExchangeRateMap: Map<string, ExchangeRate | Promise<ExchangeRate>> = new Map<string, ExchangeRate | Promise<ExchangeRate>>();
	static PriceObserverCache: Array<MutationObserver> = new Array<MutationObserver>();

	constructor()
	{
		const currency_code: string | null = localStorage.getItem('crn:cc:ae:jsapi:cache:default_currency');
		if (currency_code) {
			Currency.Default(currency_code);
		}
	}

	public orderV1(shop: string, guid: string, pin: number): Promise<Order>
	{
		let retval: Promise<Order> = new Promise
		(
			(resolve, reject) => fetch(
				"https://aes.cadabra.cloud/v1/" + shop + "/" + guid + "?pin=" + pin.toString(),
			 	{
					method: "GET" 
				}
			).then(
				(response) =>
				{
					if (!response.ok) {
						reject(response.status);
					} else {
						response.json().then(
											(body) =>
											{
												let o = new Order();
												if (!o.deserialize(body)) {
													reject(500);
													return ;
												} else if (o.request && o.request.hasExchangeRate()) {
													let exchange: [string, number] = o.request.getExchangeMap().entries().next().value;
													o.exchange(exchange[0], exchange[1]);
												}
												resolve(o);
											},
											(error) =>
											{
												reject(900);
											}
						);
					}
				},
				(error) =>
				{
					reject(901);
				}
			)
		);
		return retval;
	}

	public linkTo(order: Order, pin: number): string
	{
		if (order.expired || order.punched) {
			return "#";
		}
		let url_target_param_list: Array<string> = [];
		const url_source_param_set: URLSearchParams = new URLSearchParams(window.location.search);
		if (pin > 0) {
			url_target_param_list.push("pin=" + pin.toString());
		}
		if (url_source_param_set.has('utm_source')) {
			url_target_param_list.push("utm_source=" + url_source_param_set.get('utm_source'));
		}
		if (url_source_param_set.has('utm_medium')) {
			url_target_param_list.push("utm_medium=" + url_source_param_set.get('utm_medium'));
		}
		if (url_source_param_set.has('utm_campaign')) {
			url_target_param_list.push("utm_campaign=" + url_source_param_set.get('utm_campaign'));
		}
		if (url_source_param_set.has('utm_content')) {
			url_target_param_list.push("utm_content=" + url_source_param_set.get('utm_content'));
		}
		if (url_source_param_set.has('utm_term')) {
			url_target_param_list.push("utm_term=" + url_source_param_set.get('utm_term'));
		}
		return "https://pay.cadabra.cloud/invoice/" + order.shop + "/" + order.guid + "?" + url_target_param_list.join("&");
	}

	public goTo(order: Order, pin: number): boolean
	{
		const url: string = this.linkTo(order, pin);
		if (url.length == 0 || url == "#") {
			return false;
		} 
		window.location.replace(url);
		return true;
	}

	public switchCurrency(code: string): boolean
	{
		if (!Currency.Default(code)) {
			return false;
		}
		localStorage.setItem('crn:cc:ae:jsapi:cache:default_currency', code);
		return true;
	}

	public defaultCurrency(): string | undefined
	{
		return Currency.Code();
	}

	public rateExchange(shop: string, currency_code: string): Promise<ExchangeRate>
	{
		const cache_key: string = 'crn:cc:ae:jsapi:cache:exchange_rate:' + currency_code;
		let retval: ExchangeRate | Promise<ExchangeRate> | undefined = AE.ExchangeRateMap.get(currency_code);
		if (!retval) {
			let cache_item = localStorage.getItem(cache_key);
			if (cache_item) {
				let r = new ExchangeRate();
				if (r.deserialize(JSON.parse(cache_item!)) && r.valid) {
					return new Promise((resolve, reject) => setTimeout(() => resolve(r), 1000));
				}
			}
			retval = new Promise
			(
				function(resolve, reject): void
				{
					fetch(
						"https://aes.cadabra.cloud/v2/" + shop, 
						{ 
							method: "POST",
							headers: {
										'Content-Type': "application/json"
							},
							body: JSON.stringify(
													{
														'exchange': currency_code.toUpperCase()
													}
							)
						}
					).then(
						(response) =>
						{
							if (!response.ok) {
								reject(response.status);
							} else {
								response.json().then(
													(body) =>
													{
														if (!body.hasOwnProperty('exchange')) {
															reject(500);
															return ;
														}
														let r = new ExchangeRate();
														if (!r.deserialize(body.exchange)) {
															reject(500);
															return ;
														}
														AE.ExchangeRateMap.set(currency_code, r);
														localStorage.setItem(cache_key, JSON.stringify(r));
														resolve(r);
													},
													(error) =>
													{
														reject(900);
													}
								);
							}
						},
						(error) =>
						{
							reject(901);
						}
					)
				}
			);
			AE.ExchangeRateMap.set(currency_code, retval);
		} else if (retval instanceof ExchangeRate) {
			return new Promise((resolve, reject) => setTimeout(() => resolve(retval!), 1000));
		}
		return retval;
	}

	public rateRevision(shop: string, currency_code: string): Promise<RevisionRate>
	{
		const cache_key: string = 'crn:cc:ae:jsapi:cache:revision_rate:' + currency_code;
		let retval: RevisionRate | Promise<RevisionRate> | undefined = AE.RevisionRateMap.get(currency_code);
		if (!retval) {
			let cache_item = localStorage.getItem(cache_key);
			if (cache_item) {
				let r = new RevisionRate();
				if (r.deserialize(JSON.parse(cache_item!)) && r.valid) {
					return new Promise((resolve, reject) => setTimeout(() => resolve(r), 1000));
				}
			}
			retval = new Promise
			(
				function(resolve, reject): void
				{
					fetch(
						"https://aes.cadabra.cloud/v2/" + shop, 
						{ 
							method: "POST",
							headers: {
										'Content-Type': "application/json"
							},
							body: JSON.stringify(
													{
														'revision': {'currency_code': currency_code.toUpperCase(), 'name_space': null}
													}
							)
						}
					).then(
						(response) =>
						{
							if (!response.ok) {
								reject(response.status);
							} else {
								response.json().then(
													(body) =>
													{
														if (!body.hasOwnProperty('revision')) {
															reject(500);
															return ;
														}
														let r = new RevisionRate();
														if (!r.deserialize(body.revision)) {
															reject(500);
															return ;
														}
														AE.RevisionRateMap.set(currency_code, r);
														localStorage.setItem(cache_key, JSON.stringify(r));
														resolve(r);
													},
													(error) =>
													{
														reject(900);
													}
								);
							}
						},
						(error) =>
						{
							reject(901);
						}
					)
				}
			);
			AE.RevisionRateMap.set(currency_code, retval);
		} else if (retval instanceof RevisionRate) {
			return new Promise((resolve, reject) => setTimeout(() => resolve(retval!), 1000));
		}
		return retval;
	}

	public rateLevel(shop: string, currency_code: string): Promise<LevelRate>
	{
		const cache_key: string = 'crn:cc:ae:jsapi:cache:level_rate:' + currency_code;
		let retval: LevelRate | Promise<LevelRate> | undefined = AE.LevelRateMap.get(currency_code);
		if (!retval) {
			let cache_item = localStorage.getItem(cache_key);
			if (cache_item) {
				let r = new LevelRate();
				if (r.deserialize(JSON.parse(cache_item!)) && r.valid) {
					return new Promise((resolve, reject) => setTimeout(() => resolve(r), 1000));
				}
			}
			retval = new Promise
			(
				function(resolve, reject): void
				{
					fetch(
						"https://aes.cadabra.cloud/v2/" + shop, 
						{ 
							method: "POST",
							headers: {
										'Content-Type': "application/json"
							},
							body: JSON.stringify(
													{
														'level':  {'currency_code': currency_code.toUpperCase(), 'name_space': null}
													}
							)
						}
					).then(
						(response) =>
						{
							if (!response.ok) {
								reject(response.status);
							} else {
								response.json().then(
													(body) =>
													{
														if (!body.hasOwnProperty('level')) {
															reject(500);
															return ;
														}
														let r = new LevelRate();
														if (!r.deserialize(body.level)) {
															reject(500);
															return ;
														}
														AE.LevelRateMap.set(currency_code, r);
														localStorage.setItem(cache_key, JSON.stringify(r));
														resolve(r);
													},
													(error) =>
													{
														reject(900);
													}
								);
							}
						},
						(error) =>
						{
							reject(901);
						}
					)
				}
			);
			AE.LevelRateMap.set(currency_code, retval);
		} else if (retval instanceof LevelRate) {
			return new Promise((resolve, reject) => setTimeout(() => resolve(retval!), 1000));
		}
		return retval;
	}

	public convertPrice(shop: string, value_element: HTMLElement, currency_element: HTMLElement): void
	{
		if (!value_element || !currency_element) {
			return ;
		}

		const currency: string = currency_element.innerText.replace(/\s/g,'');
		if (!Currency.Exists(currency)) {
			return ;
		}

		const value: string = value_element.innerText.replace(/\s/g,'');
		if (!value) {
			return ;
		}

		let promise: Promise<Rate> | undefined = undefined;
		if (Currency.SignMap.get('CVE') == currency) {
			promise = this.rateExchange(shop, Currency.Code() || "CVE");
		} else if (Currency.SignMap.get('CLP') == currency) {
			promise = this.rateLevel(shop, Currency.Code() || "CLP");
		} else if (Currency.SignMap.get('CRC') == currency) {
			promise = this.rateRevision(shop, Currency.Code() || "CRC");
		} else {
			return ;
		}

		value_element.setAttribute('data-ae-backup', value);
		currency_element.setAttribute('data-ae-backup', currency);

		value_element.innerText = "...";
		currency_element.innerText = ".";

		promise.then
		(
			(rate) =>
			{
				value_element.innerText = rate.convert(Number(value)).toFixed(2);
				currency_element.innerText = rate.currency_sign;
				const observer_callback = function(mutation_array: MutationRecord[], observer: MutationObserver)
				{
					console.log(value_element.getAttribute('data-ae-backup') + ";" + value_element.getAttribute('data-product-price-def-str') + ";" + value_element.innerText);
					if (value_element.getAttribute('data-product-price-def-str') != value_element.getAttribute('data-ae-backup')
						||
						value_element.getAttribute('data-product-price-def-str') == value_element.innerText) {
						value_element.setAttribute('data-ae-backup', value_element.getAttribute('data-product-price-def-str')!);
						value_element.innerText = rate.convert(Number(value_element.getAttribute('data-product-price-def-str'))).toFixed(2);
					}
				}
				AE.PriceObserverCache[AE.PriceObserverCache.push(new MutationObserver(observer_callback)) - 1].observe
				(
					value_element,
					{ attributes: true, childList: false, subtree: false, characterData: false }
				);
			},
			(error) =>
			{
				value_element.innerText = value;
				currency_element.innerText = currency;
			}
		)
	}

	public loadPrice(shop: string, guid: string, element: HTMLElement): void
	{
		if (!element) {
			return ;
		} else if (element instanceof HTMLAnchorElement) {
			element.href = "#";
		} else {
			element.addEventListener("click", () => {});
		}
		element.innerText = "...";
		this.orderV1(shop, guid, 0).then
		(
			(order) =>
			{
				element.innerText = order.formatted_total;
				if (element instanceof HTMLAnchorElement) {
					element.href = this.linkTo(order, 0);
				} else {
					element.addEventListener("click", () => this.goTo(order, 0));
				}
			},
			(error) =>
			{
				element.innerText = "—"
			}
		)
	}
}

export let API: AE = new AE();