import Serializable from './Serializable';

export class Currency
{
	private static DefaultCode?: string = undefined;

	static readonly SignMap: Map<string, string> = new Map<string, string>
	(
		[
			['USD', "$"],
			['EUR', "€"],
			['GBP', "£"],
			['RUB', "₽"],
			['AMD', "֏"],
			['CVE', "▽"],
			['CLP', "‡"],
			['CRC', "•"],
			['CCC', "Ꝣ"]
		]
	);

	static get SignArray(): Array<[string, string]>
	{
		let retval: Array<[string, string]> = [];
		Currency.SignMap.forEach((value: string, key: string) => retval.push([key, value]));
		return retval;
	}

	static Sign(code?: string): string | undefined
	{
		return code ? Currency.SignMap.get(code.toUpperCase()) : Currency.DefaultCode ? Currency.SignMap.get(Currency.DefaultCode) : undefined;
	}

	static Code(sign?: string): string | undefined
	{
		return sign ? (Currency.SignArray.find((item: [string, string]) => item[1] === sign) || [undefined, undefined])[0] : Currency.DefaultCode;
	}

	static Exists(key: string): boolean
	{
		return (key.length < 3 ? Currency.Code(key) : Currency.Sign(key)) != undefined;
	}

	static Default(code: string): boolean
	{
		code = code.toUpperCase();
		if (Currency.SignMap.has(code) && Currency.DefaultCode != code) {
			Currency.DefaultCode = code; 
			return true;
		}
		return false;
	}
}

export class Rate implements Serializable<Rate>
{
	shop_name: string = "";
	timestamp: number = 0;
	ttl: number = 0;
	ip_address: string = "0.0.0.0";
	country_code: string = "XX";
	vat_rate: number = 0;
	currency_code: string = "XXX";

	constructor(currency_code: string)
	{
		this.currency_code = currency_code;
	}

	public get valid(): boolean
	{
		return this.timestamp != 0 && this.request_date >= this.expiration_date;
	}

	public get request_date(): Date
	{
		return new Date(this.timestamp * 1000);
	}

	public get expiration_date(): Date
	{
		return new Date((this.timestamp + this.ttl) * 1000);
	}

	public get currency_sign(): string
	{
		return Currency.Sign(this.currency_code) || "?";
	}

	public get formatter(): Intl.NumberFormat
	{
		return new Intl.NumberFormat(
										'en-US',
										{
											style: 'currency',
											currency: this.currency_code
										},
//										minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
//										maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
		);
	}

	deserialize(input: any): boolean
	{
		if (!input.hasOwnProperty('shop_name')) {
			return false;
		}
		this.shop_name = input.shop_name;

		if (!input.hasOwnProperty('timestamp')) {
			return false;
		}
		this.timestamp = input.timestamp;

		if (!input.hasOwnProperty('ttl')) {
			return false;
		}
		this.ttl = input.ttl;

		if (!input.hasOwnProperty('ip_address')) {
			return false;
		}
		this.ip_address = input.ip_address;

		if (!input.hasOwnProperty('country_code')) {
			return false;
		}
		this.country_code = input.country_code;

		if (!input.hasOwnProperty('vat_rate')) {
			this.vat_rate = 0;
		} else {
			this.vat_rate = input.vat_rate;
		}

		if (!input.hasOwnProperty('currency_code')) {
			return false;
		}
		this.currency_code = input.currency_code;

		return true;
	}

	convert(value: number): number
	{
		return NaN;
	}
}

export class LevelRate extends Rate
{
	list: number[] = [];

	constructor()
	{
		super("CLP");
	}

	deserialize(input: any): boolean
	{
		if (!super.deserialize(input)) {
			return false;
		} else if (!input.hasOwnProperty('list')) {
			return false;
		}

		this.list = input.list;

		return true;
	}

	convert(value: number): number
	{
		return (!Number.isInteger(value) || value > this.list.length) ? NaN : this.list[value - 1] / 100;
	}
}

export class RevisionRate extends Rate
{
	list: number[] = [];

	constructor()
	{
		super("CRC");
	}

	deserialize(input: any): boolean
	{
		if (!super.deserialize(input)) {
			return false;
		} else if (!input.hasOwnProperty('list')) {
			return false;
		}

		this.list = input.list;

		return true;
	}

	convert(value: number): number
	{
		return (!Number.isInteger(value) || value > this.list.length) ? NaN : this.list[value - 1] / 100;
	}
}

export class ExchangeRate extends Rate
{
	multiplicator: number = 1;

	constructor()
	{
		super("CVE");
	}

	deserialize(input: any): boolean
	{
		if (!super.deserialize(input)) {
			return false;
		} else if (!input.hasOwnProperty('multiplicator')) {
			return false;
		}

		this.multiplicator = input.multiplicator;

		return true;
	}

	convert(value: number): number
	{
		return value / this.multiplicator;
	}
}